import { Fab } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useEffect, useState } from "react";

export default function GoToTopNavigationButton() {
    const [displayButton, setDisplayButton] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            setDisplayButton(window.scrollY > 400)
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            {
                displayButton && (
                    <Fab onClick={scrollTop}
                        color='primary'
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20
                        }}
                    >
                        <ArrowUpwardIcon />
                    </Fab>
                )
            }
        </>
    )
}