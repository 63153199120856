import { Box } from '@mui/material';
import './FooterBar.css';
import Newsletter from '../Newsletter/Newsletter';
import { Link } from 'react-router-dom';
import ConfigService from "../../../UseCases/Tools/ConfigService";
import config from '../../../config.json'
import { useEffect, useState } from 'react';
import MailLink from '../MailLink/MailLink';

export default function FooterBar() {
    const [contactMail, setContactMail] = useState('')

    useEffect(() => {
        const service = new ConfigService()
        service.fetchConfigFile(config.config_file)
            .then((cfg) => setContactMail(cfg['contact_mail']))
    }, [])


    return (
        <Box className="footer">

            <Newsletter />

            <div className="desktop">
                <div className="contact">
                    <h3>Contact</h3>
                    <p>
                        <MailLink to={contactMail} text={contactMail} />
                    </p>
                </div>

                <div className="sitemap">
                    <h3>Plan du site</h3>
                    <ul>
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/project">Le projet</Link></li>
                        <li><Link to="/publications">Publications</Link></li>
                        <li><Link to="/press">Presse</Link></li>
                        <li><Link to="/faq">F.A.Q</Link></li>
                        <li><Link to="/documents">Documents</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>

            </div>

            <div className="legals-links">
                <Link to='/mentions'>Mentions légales</Link>
            </div>
            <div className="copyright">
                © Pas de carrière à Laà-Mondrans - 2024
            </div>
        </Box >
    );
}