import { useEffect, useState } from "react"
import { Publication } from "../../UseCases/Models/Publication"
import SummaryComponent from "../Components/Publications/SummaryComponent"
import { Button, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useFetchRepository } from "../../UseCases/Tools/useRepository"
import { compareDates } from "../../UseCases/Tools/DateUtils"

export default function Home() {
    const [loading, setLoading] = useState(true)
    const [publicationsList, setPublicationsList] = useState<Publication[]>()
    const navigate = useNavigate()

    const fetch = useFetchRepository()

    useEffect(() => {
        setLoading(true)
        fetch.publications.listPublications()
            .then((data) => {
                setLoading(false)
                setPublicationsList(data.sort((a, b) => compareDates(a.date, b.date)).reverse())
            })

    }, [])

    const go = () => {
        navigate('/publications')
    }

    return (
        <div className="home">
            <div className="publications">
                {(loading)
                    ? <div className="circular-progress"><CircularProgress /></div>
                    : publicationsList?.map((pub, i) => (
                        <SummaryComponent publication={pub!} key={i} />
                    ))
                }

                <div className="more-publications">
                    {
                        !loading &&
                        <Button variant="contained" onClick={go}>Plus de publications</Button>
                    }
                </div>
            </div>
        </div>
    )
}