import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNotifications } from "./useNotifications";
import Markdown from "../Markdown/Markdown";

type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

export default function Notifications() {
    const {
        isDisplayed,
        toggle,
        notificationType,
        notificationContent,
    } = useNotifications()

    return (
        <Dialog open={isDisplayed} onClose={() => toggle()}>
            <Alert severity={notificationType as AlertColor} variant="outlined">
                <DialogTitle>Informations importantes</DialogTitle>
                <DialogContent>
                    <div className="infos-content">
                        <Markdown input={notificationContent?.body} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color={notificationType as ButtonColor} onClick={() => toggle()}>Ok</Button>
                </DialogActions>
            </Alert>
        </Dialog >
    )
}