import { Outlet } from "react-router-dom";
import NavBar from "../Components/NavBar/NavBar";
import FooterBar from "../Components/FooterBar/FooterBar";
import { Box } from "@mui/material";
import GoToTopNavigationButton from "../Components/GoToTopNavigationButton/GoToTopNavigationButton";
import Notifications from "../Components/Notifications/Notifications";
import { NotificationsProvider } from "../Components/Notifications/useNotifications";
import Sidebar from "../Components/Sidebar/Sidebar"

import config from '../../config.json';

const pages = [
    { target: '/', name: 'Accueil' },
    { target: '/project', name: 'Le projet' },
    { target: '/publications', name: 'Publications' },
    { target: '/press', name: 'Presse' },
    { target: '/faq', name: 'F.A.Q' },
    { target: '/documents', name: 'Documents' },
    { target: '/contact', name: 'Contact' },
];

export default function Home() {
    return (
        <NotificationsProvider>
            <Box className="App">

                <GoToTopNavigationButton />

                <Notifications />

                <header>
                    <NavBar menu={pages} />
                </header>

                <div className="site-content">
                    <main>
                        <div className="main-content">
                            <Outlet />
                        </div>
                    </main>

                    <aside>
                        <div className="aside-content">
                            <Sidebar pageId={config.static_pages.project} />
                        </div>
                    </aside>
                </div>

                <footer >
                    <FooterBar />
                </footer>


            </Box>
        </NotificationsProvider>
    )
}