import CacheService, { ICacheService } from "../Tools/CacheService"
import FetchService from "../Tools/FetchService"

import config from '../../config.json'

export interface IFetchRepository {
    fetch<T>(onData: (data: T) => any, repository: Function): any
}

export type FetchCallback = (error?: Error, data?: Response) => void
export type PromiseResolve<T> = (value?: T | PromiseLike<T>) => void;
export type PromiseReject = (error?: any) => void;

export class FetchRepository implements IFetchRepository {
    private fetcher: FetchService
    private cacheService: ICacheService

    constructor() {
        this.fetcher = new FetchService()
        this.cacheService = new CacheService(config.cache_name)
    }

    private fetchApi = (url: string, cb: FetchCallback) => this.fetcher.fetchApi(url, cb)
    private fetchCache = (url: string, cb: FetchCallback) => this.cacheService.fetchData(url, cb)

    fetch<T>(onData: (data: T) => any, repository: Function) {
        const onResponse = async (response: Response) => {
            const data = await response.text()
            try {
                const json: T = JSON.parse(data)
                onData(json)
            } catch (e) {
                console.error('Error: ', e)
            }
        }
        repository((url: string) => this.onFetch(url, onResponse))
    }

    private onCacheFirst = (url: string) => {
        return new Promise((resolve: PromiseResolve<Response>, reject: PromiseReject): void =>
            this.fetchCache(url, (error, data) => {
                if (error) reject(error)
                else resolve(data)
            })
        )
    }

    private onApi = (url: string) => {
        return new Promise((resolve: PromiseResolve<Response>, reject: PromiseReject): void =>
            this.fetchApi(url, (error, data) => {
                if (error) reject(error)
                else resolve(data)
            })
        )
    }

    private onFetch = (url: string, onData: (response: Response) => void) => {
        this.onCacheFirst(url)
            .then(response => {
                // console.log("Cached data: ", response)
                onData(response!)
            })
            .catch(error => {
                this.onApi(url)
                    .then(response => {
                        // console.log('API data: ', response)
                        this.cacheService.putData(url, response!.clone())
                        onData(response!)
                    })
                    .catch(error => {
                        // console.error(error)
                    })
            })
    }

}