import { useEffect, useRef, useState } from "react";
import { Publication } from "../../../UseCases/Models/Publication";
import { getFirstImage, getTitle, getSummary } from "../../../UseCases/Tools/PublicationUtils";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import './SummaryComponent.css';
import { ExtendedImage, useResizeOnLoad } from "../Markdown/Markdown";

type Props = {
    publication: Publication
}

type Image = {
    alt: string,
    url: string
}

export default function SummaryComponent({ publication }: Props) {
    const [image, setImage] = useState<Image>({ alt: '', url: '' })
    const [title, setTitle] = useState('')
    const [summary, setSummary] = useState('')

    const elementRef = useRef<HTMLDivElement>(null)
    const maxWidth = useResizeOnLoad(elementRef, image)

    const navigate = useNavigate()


    useEffect(() => {
        const { body } = publication

        const title = getTitle(body)
        if (title) {
            setTitle(title)
        }

        const publicationImage = getFirstImage(body)
        if (publicationImage) {
            const [alt, url] = publicationImage

            if (url) {
                setImage({ alt: alt, url: url })
            }
        }

        const summary = getSummary(body)
        if (summary) {
            setSummary(summary)
        }

    }, [publication])

    const getDate = () => {
        return new Intl.DateTimeFormat('fr', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short'
        }).format(new Date(publication.date))
    }

    const openPublication = () => {
        navigate(`/publication/${publication.id}`)
    }

    return (
        <div className="summary-content" onClick={openPublication}>
            <p className="date">{getDate()}</p>
            <h1>{title}</h1>
            <div className="content" ref={elementRef}>
                {
                    image
                        ? <ExtendedImage maxWidth={maxWidth} alt={image.alt} src={image.url} />
                        : <></>
                }
                <Markdown children={summary} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
            </div>
        </div>
    )

}