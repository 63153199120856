import { createContext, useContext, useEffect, useState } from "react";
import { Publication } from "../../../UseCases/Models/Publication";
import { useFetchRepository } from "../../../UseCases/Tools/useRepository";

type NotificationsContextActions = {
    isDisplayed: boolean
    toggle: Function
    hasNotification: boolean
    notificationType?: string
    notificationContent?: Publication
}

const NotificationsContext = createContext<NotificationsContextActions>({ isDisplayed: false, toggle: () => { }, hasNotification: false })

const NotificationsProvider = ({ children }: any) => {
    const [display, setDisplay] = useState(false)

    const toggleDisplayNotifications = () => setDisplay(!display)

    const [notificationContent, setNotificationContent] = useState<Publication>()
    const [hasNotification, setHasNotification] = useState(false)
    const [notificationType, setNotificationType] = useState('info')

    const fetch = useFetchRepository()

    const fetchNotifications = () => {
        fetch.publications.getPublication('1-WT-lrGIcB7qfqkYSiESjutOa9tLBEc-').then(notif => {
            setNotificationContent(notif)
            if (notif.name) {

                if (notif.name.startsWith('_')) {
                    setHasNotification(false)
                    return
                }
                else {
                    setHasNotification(true)
                }

                let type = 'info'
                switch (notif.name.substring(0, 2)) {
                    case 'x.':
                        type = 'error'
                        break;
                    case '!.':
                        type = 'warning'
                        break;
                    case 'v.':
                        type = 'success'
                        break;
                    case 'i.':
                    default:
                        type = 'info'
                }

                setNotificationType(type)
            }
        })
    }

    useEffect(() => {
        fetchNotifications()
    }, [])

    return (
        <NotificationsContext.Provider value={{
            isDisplayed: display,
            toggle: toggleDisplayNotifications,
            hasNotification: hasNotification,
            notificationType: notificationType,
            notificationContent: notificationContent
        }}>
            {children}
        </NotificationsContext.Provider>
    )

}

const useNotifications = () => useContext(NotificationsContext)

export { NotificationsProvider, useNotifications }