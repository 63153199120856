import { Routes, Route } from "react-router-dom";

import './App.css';
import './Pages/default_mobile.css';
import './Pages/default_desktop.css';

import Layout from './Pages/Layout';
import Home from './Pages/Home';
import Publications from "./Pages/Publications";
import MarkdownPage from "./Components/MarkdownStaticPage/MarkdownPage";
import PublicationDetails from "./Pages/PublicationDetails";
import Contact from "./Pages/Contact";
import Faq from "./Pages/Faq";

import config from '../config.json';
import Press from "./Pages/Press";

function App() {
  return (
    <Routes>

      <Route path="/" element={<Layout />}>

        <Route index element={<Home />} />
        <Route path="/project" element={<MarkdownPage pageId={config.static_pages.project} />} />
        <Route path="/press" element={<Press />} />
        <Route path="/contact" element={<Contact />} />

        {/* <Route path="/faq" element={<MarkdownPage pageId={config.static_pages.faq} />} /> */}
        <Route path="/faq" element={<Faq id={config.static_pages.faq} />} />
        <Route path="/documents" element={<MarkdownPage pageId={config.static_pages.documents} />} />

        <Route path="/publications" element={<Publications />} />
        <Route path="/publication/:id" element={<PublicationDetails />} />

        <Route path="/mentions" element={<MarkdownPage pageId={config.static_pages.mentions} />} />

      </Route>

    </Routes>

  )
}

export default App;
