import Message from "../../UseCases/Models/Message";
import config from '../../config.json';

export type FetchUrl = (url: string) => any

export interface IAPIPublicationsRepository {
  listPublications(fetch: FetchUrl): void
  getPublication(fetch: FetchUrl, id: string): void
  listPressArticles(fetch: FetchUrl): void
}

export interface IAPIContactsRepository {
  subscribe(emailAddress: string): Promise<any>
  contact(message: Message): Promise<any>
}

export interface IAPIFilesRepository {
  getFile(fetch: FetchUrl, id: string): void
}

export class APIRepository implements IAPIPublicationsRepository, IAPIContactsRepository, IAPIFilesRepository {

  listPublications(fetch: FetchUrl): void {
    const url = `${config.base_api}${config.api_endpoints.list_publications}`
    fetch(url)
  }

  getPublication(fetch: FetchUrl, id: string): void {
    const url = `${config.base_api}${config.api_endpoints.get_publication}${id}`
    fetch(url)
  }

  listPressArticles(fetch: FetchUrl): void {
    const url = `${config.base_api}${config.api_endpoints.list_press}`
    fetch(url)
  }

  getFile(fetch: FetchUrl, id: string): void {
    const url = `${config.base_api}${config.api_endpoints.get_publication}${id}`
    fetch(url)
  }

  async subscribe(emailAddress: string) {
    const url = `${config.base_api}${config.api_endpoints.subscribe}`
    const request = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ contact: emailAddress })
    })
    return await request.json()
  }

  async contact(message: Message) {
    const url = `${config.base_api}${config.api_endpoints.contact}`
    const request = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(message)
    })
    return await request.json()
  }

}
