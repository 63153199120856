import { CircularProgress, FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import { ContactRepository } from "../../../UseCases/Repositories/ContactRepository";
import { APIRepository } from "../../../Repositories/DataSources/APIRepository";

export default function Newsletter() {
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataInput, setDataInput] = useState({
        email: '', error: '', valid: true
    });

    const handleSubmit = (e: any, onSubmit: Function) => {
        e.preventDefault()
        if (success) {
            return
        }
        onSubmit()
    }

    const onChanged = (e: any) => {
        const { value } = e.target;
        setDataInput({ ...dataInput, email: value })
    }

    const isValid = () => {
        let error = ''

        let valid = dataInput.valid;
        if (dataInput.email !== undefined) {
            valid = dataInput.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false
            error = 'Veuillez saisir une adresse email valide'
        }

        setDataInput({
            ...dataInput,
            valid: valid,
            error: error
        })

        return valid
    }

    const onSubmit = () => {
        if (isValid()) {
            console.log("validation")

            setLoading(true)
            const repository = new ContactRepository(new APIRepository())
            repository.subscribe(dataInput.email)
                .then((succeed) => {
                    setSuccess(succeed)
                    setLoading(false)
                })
                .catch((reason) => {
                    setLoading(false)
                    setSuccess(false)
                })
        }
    }


    return (
        <div className="newsletter">
            <h3>Recevoir des informations</h3>
            <p className="desktop-display">
                Nous vous enverrons un e-mail pour vous tenir informés des dernières informations que nous aurons ou des évènements qu’on nous organiserons.
            </p>

            <form onSubmit={e => handleSubmit(e, onSubmit)}
            >
                <FormControl variant="outlined" fullWidth>
                    <InputLabel >Saisissez votre adresse email</InputLabel>
                    <FilledInput
                        value={dataInput?.email}
                        required
                        onChange={onChanged}
                        disabled={success || loading}
                        style={{ backgroundColor: '#fff' }}
                        type='text'
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="search" onClick={onSubmit} disabled={success}>
                                    {success ? <CheckIcon /> : <SendIcon />}
                                </IconButton>
                                {loading && (
                                    <CircularProgress size={35} sx={{ position: 'absolute' }} />
                                )}
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error hidden={dataInput.valid}>
                        {dataInput.error}
                    </FormHelperText>
                </FormControl>
            </form>
        </div>

    )
}