import { FetchCallback } from "../Repositories/Repository"
import StorageService from "./StorageService"

export interface ICacheService {
    isAvailable(): boolean
    putData(url: string, response: Response): void
    removeData(url: string): void
    clear(): void
    fetchData(url: string, cb: FetchCallback): void
}

export default class CacheService implements ICacheService {
    private cacheName: string
    private available: boolean = "caches" in window
    private storeService: StorageService

    constructor(cacheName: string) {
        this.cacheName = cacheName
        this.storeService = new StorageService('sessionStorage')
    }

    isAvailable(): boolean {
        return this.available
    }

    purgeData(url: string) {
        const createdDate = this.storeService.getData(url)
        if (createdDate === null || (createdDate !== null && Date.now() - createdDate >= 3_600_000)) {
            // console.log("Purge cached data")
            this.removeData(url)
        }
    }

    async fetchData(url: string, cb: FetchCallback) {
        this.purgeData(url)
        const cache = await caches.open(this.cacheName)
        const response = await cache.match(url)

        if (response?.ok) {
            cb(undefined, response)
        }
        cb(new Error('request does not match'))
    }

    putData(url: string, response: Response): void {
        if (!this.storeService.isAvailable()) {
            // console.log("Store not available")
            return
        }

        this.storeService.putData(url, Date.now())
        caches.open(this.cacheName).then(cache => cache.put(url, response))
    }

    removeData(url: string): void {
        caches.open(this.cacheName).then((cache => cache.delete(url)))
    }

    async clear(): Promise<void> {
        // console.log("Clear cache")
        const cache = await caches.open(this.cacheName)
        const keys = await cache.keys()
        
        for(let key of keys) {
            // console.log("Delete [%s]", key.url)
            this.removeData(key.url)
        }
    }

}