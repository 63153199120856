import { Button, CircularProgress, Snackbar, TextField, Alert, Divider } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from "react";
import { APIRepository } from "../../Repositories/DataSources/APIRepository";
import { ContactRepository } from "../../UseCases/Repositories/ContactRepository";
import ConfigService from "../../UseCases/Tools/ConfigService";
import config from '../../config.json'
import MailLink from "../Components/MailLink/MailLink";

export interface FieldError {
    error: boolean,
    reason: string
}

export interface Field {
    field: string,
    error: FieldError
}

export default function Contact() {
    const [contactMail, setContactMail] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [from, setFrom] = useState<Field>({ field: '', error: { error: false, reason: '' } });
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState<Field>({ field: '', error: { error: false, reason: '' } });

    useEffect(() => {
        const service = new ConfigService()
        service.fetchConfigFile(config.config_file)
            .then((cfg) => setContactMail(cfg['contact_mail']))
    }, [])

    const [displaySnackbar, setDisplaySnackbar] = useState({
        display: false,
        message: 'Merci pour votre message',
        error: false
    });

    const validateRequired = (value: string): FieldError => {
        if (!value) {
            return {
                error: true,
                reason: 'Le champ est requis',
            };
        }
        return { error: false, reason: '' };
    };

    const validateEmail = (value: string, error: FieldError): FieldError => {
        if (value && !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            return {
                error: true,
                reason: 'Veuillez saisir une adresse e-mail valide'
            };
        }
        return error.error ? error : { error: false, reason: '' };
    };

    const onChangedFrom = ({ target }: any) => {
        setFrom({ ...from, field: target.value });
    };

    useEffect(() => {
        if (from.error.error) {
            let error = validateRequired(from.field);
            error = validateEmail(from.field, error);
            setFrom({ ...from, error: error });
        }
    }, [from]);

    const onChangedSubject = ({ target }: any) => {
        setSubject(target.value);
    };

    const onChangedBody = ({ target }: any) => {
        setBody({ ...body, field: target.value });
    };

    useEffect(() => {
        if (body.error.error) {
            setBody({ ...body, error: validateRequired(body.field) });
        }
    }, [body]);

    const onSubmit = (e: any) => {
        e.preventDefault();

        let fromError = validateRequired(from.field);
        fromError = validateEmail(from.field, fromError);
        if (fromError.error) {
            setFrom({ ...from, error: fromError });
        }

        let bodyError = validateRequired(body.field);
        if (bodyError.error) {
            setBody({ ...body, error: bodyError });
        }

        if (!fromError.error && body.error) {
            setLoading(true);
            const repository = new ContactRepository(new APIRepository());
            repository.contact({
                from: from.field,
                subject: subject ?? '',
                body: body.field
            }).then(succeed => {
                setLoading(false);
                setSuccess(succeed);

                setDisplaySnackbar({
                    display: true,
                    error: !succeed,
                    message: succeed ? 'Merci pour votre message' : 'Impossible d’envoyer le message'
                });
            }).catch(reason => {
                setLoading(false);
                setSuccess(false);
            });
        }
    };

    const toggleSnackbar = () => setDisplaySnackbar({ ...displaySnackbar, display: !displaySnackbar });

    return (
        <div className="contact-us">
            <h3>Contactez nous ici</h3>
            <form className="form" onSubmit={onSubmit}>
                <TextField required variant="filled" label="Adresse e-mail"
                    value={from.field}
                    onChange={onChangedFrom}
                    error={from.error.error}
                    helperText={from.error.reason} />

                <TextField variant="filled" label="Objet"
                    value={subject}
                    onChange={onChangedSubject} />

                <TextField required className="form-body"
                    value={body.field}
                    onChange={onChangedBody}
                    multiline
                    minRows={8}
                    variant="filled"
                    label="Message"
                    error={body.error.error}
                    helperText={body.error.reason} />

                <>
                    <Button variant="contained"
                        onClick={onSubmit}
                        disabled={loading || success}
                        endIcon={success ? <CheckIcon /> : <SendIcon />}>
                        Envoyer
                        {loading && <CircularProgress size={25} sx={{ position: 'relative', left: 28 }} />}
                    </Button>
                </>
            </form>

            <Snackbar
                open={displaySnackbar.display} onClose={toggleSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={5000}>
                <Alert
                    severity={displaySnackbar.error ? 'error' : 'success'}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {displaySnackbar.message}
                </Alert>
            </Snackbar>

            <Divider />

            <h3>Ou depuis votre boîte mail</h3>
            <p>à l’adresse suivante: <br />
                {
                    (contactMail)
                        ? <MailLink to={contactMail} text={contactMail} />
                        : <MailLink to='pasdecarrierealaamondrans@gmail.com' text='pasdecarrierealaamondrans@gmail.com' />
                }
            </p>
        </div>
    );
}
