import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { TextUnderline, remarkUnderline } from './Plugins/TextUnderlinePlugin';
import { remarkAlignment } from './Plugins/TextAlignmentPlugin';
import { TextHighlighting, remarkHighlight } from './Plugins/TextHighlightingPlugin';
import { TextColorizing, remarkColor } from './Plugins/TextColorPlugin';
import { TableRender, remarkTableResize } from './Plugins/FullsizeTablePlugin';

export type Image = {
    alt?: string
    src?: string
    title?: string
    maxWidth?: number
}

export function ExtendedImage({ alt, src, title, maxWidth }: Image) {

    const metaWidth = alt?.match(/{([0-9^}]+)x/)
    const metaHeight = alt?.match(/x([0-9^}]+)}/)
    const width = metaWidth ? metaWidth[1] : ""
    const height = metaHeight ? metaHeight[1] : ""
    const hasCaption = alt?.toLowerCase().includes('{caption:')
    const caption = alt?.match(/{caption: (.*?)}/)?.pop()

    return (
        <span style={{ display: 'inline-block' }}>
            <span className="image-wrapper" style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <img
                    src={src}
                    alt={alt}
                    title={title}
                    width={width}
                    height={height}
                    style={{ maxWidth: maxWidth }}
                />
                {
                    hasCaption && <span className="img-caption" aria-label={caption} style={{
                        color: '#999',
                        fontSize: '12px'
                    }}>{caption}</span>
                }
            </span>
        </span>
    )
}

type IFrame = {
    src?: string
    title?: string
    width?: string | number
    height?: string | number
    maxWidth?: number
}

function ResponsiveIframe({ src, title, width, height, maxWidth }: IFrame) {
    const parseValue = (value?: string | number): number | undefined => {
        if (value && typeof value === 'string') {
            return parseInt(value)
        }
        return value as number | undefined
    }

    let w: number | undefined = parseValue(width)
    let h: number | undefined = parseValue(height)

    w = w ? w : (maxWidth! - 10);
    h = h ? h : w / (4 / 3)

    return (
        <div className='iframe-wrapper' style={{ maxWidth: maxWidth }}>
            <iframe title={title} src={src} width={w} height={h} />
        </div>
    )
}

function CustomParagraphComponent({ node, children }: any) {
    const alignProp = node.properties.align

    const pProps = { textAlign: alignProp, }

    return (
        <>
            {
                pProps ?
                    <p style={pProps}>
                        {children}
                    </p>
                    : <p>{children}</p>
            }
        </>
    )
}

export const useResizeOnLoad = (elementRef: RefObject<HTMLDivElement>, onProps?: any) => {
    const getWidth = useCallback(() => elementRef?.current?.offsetWidth, [elementRef])
    const [width, setWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
        const handleResize = () => setWidth(getWidth())

        if (elementRef.current) {
            setWidth(getWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [elementRef, getWidth, onProps])

    return width

}

export const useResize = (elementRef: RefObject<HTMLDivElement>) => {
    const getWidth = useCallback(() => elementRef?.current?.offsetWidth, [elementRef])
    const [width, setWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
        const handleResize = () => setWidth(getWidth())

        if (elementRef.current) {
            setWidth(getWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [elementRef, getWidth])

    return width
}

export default function Markdown({ input }: { input: any }) {
    const elementRef = useRef<HTMLDivElement>(null)
    const maxWidth = useResizeOnLoad(elementRef, input)

    return (
        <div className="markdown-content">
            <div className="markdown-component" ref={elementRef}>
                <ReactMarkdown
                    children={input}
                    remarkPlugins={[
                        remarkGfm,
                        remarkUnderline,
                        remarkAlignment,
                        remarkHighlight,
                        remarkColor,
                        remarkTableResize,
                    ]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        img: ({ ...props }) => <ExtendedImage maxWidth={maxWidth} {...props} />,
                        iframe: ({ ...props }) => <ResponsiveIframe maxWidth={maxWidth} {...props} />,
                        u: ({ ...props }) => <TextUnderline {...props} />,
                        p: ({ ...props }) => <CustomParagraphComponent {...props} />,
                        mark: ({ ...props }) => <TextHighlighting {...props} />,
                        span: ({ ...props }) => <TextColorizing {...props} />,
                        td: ({ ...props }) => <TableRender {...props} />,

                    }}
                />
            </div>
        </div>
    )
}