import { createContext, useContext, useEffect } from "react"
import CacheService from "./CacheService"
import config from "../../config.json";

type KeyboardActionHandler = {
    handle: (e: any) => any
}

const DefaultKeyboardActionHandler: KeyboardActionHandler = {
    handle: (e: any) => {
        if (e.shiftKey && e.keyCode === 82) {
            e.preventDefault()
            // console.log("Reload")
            const cache = new CacheService(config.cache_name)
            cache.clear()
            window.location.reload()
        }
    }
}

const KeyboardActionHandlerContext = createContext<KeyboardActionHandler>(DefaultKeyboardActionHandler)

const KeyboardActionHandlerProvider = ({ children }: any) => {
    const { handle } = DefaultKeyboardActionHandler

    useEffect(() => {
        window.addEventListener('keypress', handle)

        return () => window.removeEventListener('keypress', handle)
    }, [handle])

    return (
        <KeyboardActionHandlerContext.Provider value={DefaultKeyboardActionHandler}>
            {children}
        </KeyboardActionHandlerContext.Provider>
    )
}


const useKeyboardActionHandler = () => useContext(KeyboardActionHandlerContext)

export { KeyboardActionHandlerProvider, useKeyboardActionHandler }