import { visit } from 'unist-util-visit'

const remarkTableResize = () => {

    const defLinePattern = /(\| ?[:-]+ ?)+\|/

    const transformer = (tree: any, file: any) => {
        const src = file.value

        visit(tree, 'table', (node, index, parent) => {
            const start = node.position.start.offset
            const end = node.position.end.offset
            const tableString = src.substring(start, end)

            const defRow = tableString.match(new RegExp(defLinePattern, 'm'))
            // console.log("DefRow:", defRow)

            const colsDef = defRow[0].split(/\|/)
                .filter((value: string) => value !== '')
                .map((value: string) => value.replaceAll(/[ :]+/ig, ''))
                .map((value: string) => value.length)

            // console.log("ColsDef:", colsDef)

            const finalSize = colsDef.reduce((s1: number, s2: number) => s1 + s2)
            // console.log("Full size: ", finalSize)

            const columnsSizes = colsDef.map((size: number) => 100 / (finalSize / size))
            // console.log("Cols size: ", columnsSizes)

            visit(node, 'tableCell', (cellNode, i, p) => {
                // console.log(i, columnsSizes[i ?? 0])

                cellNode.data = {
                    hProperties: {
                        width: columnsSizes[i ?? 0]
                    }
                }
            })
        })
    }

    return transformer
}

const render = (node: any) => {
    const { width } = node
    const { style } = node

    return (
        <td style={{ width: `${width}%`, textAlign: style?.textAlign ?? 'left' }}>
            {node.children}
        </td>
    )
}

export { remarkTableResize, render as TableRender }