const imagePattern = /(!\[(.*)\](\((.+)\)|\[([0-9]+)\]))/
const headerPattern = /^#(.*)$/

export function getFirstImage(content: string): string[] | null {
    // detect first image
    const rex = RegExp(imagePattern, 'im')
    const result = rex.exec(content)

    if (result !== null) {
        return [result[2], result[4]]
    }

    return null;
}

export function getTitle(content: string): string | null {
    // find first title
    const rex = RegExp(headerPattern, 'im')
    const result = rex.exec(content)

    if (result !== null) {
        return result[1]
    }

    return null;
}

export function getSummary(content: string, maxChar: number = 200): string | null {
    // find first 150 char
    const splited = content.split('\n').filter((line) => line.length > 0)
    const imgRex = RegExp(imagePattern, 'im')
    const headerRex = RegExp(headerPattern, 'im')

    let count = 0;
    const summary = []
    for (let line of splited) {

        if (!imgRex.test(line) && !headerRex.test(line)) {
            
            if (count + line.length >= maxChar) {
                const offset = maxChar - count
                line = line.substring(0, offset)
                summary.push(`${line}...`)
                break
            }
            
            count += line.length
            summary.push(line)
        }
    }
    return summary.join('\n');
}