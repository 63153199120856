import { createContext, useContext } from 'react';
import { FetchRepository } from '../Repositories/Repository';
import { APIRepository } from '../../Repositories/DataSources/APIRepository';
import { FilesRepository, IFilesRepository, IPublicationsRepository, PublicationsRepository } from '../Repositories/PublicationsRepository';

type RepositoryServices = {
    files: IFilesRepository,
    publications: IPublicationsRepository
}

const fetchService = new FetchRepository()
const apiRepo = new APIRepository()

const repoServices: RepositoryServices = {
    files: new FilesRepository(apiRepo, fetchService),
    publications: new PublicationsRepository(apiRepo, fetchService)
}

const FetchRepositoryContext = createContext<RepositoryServices>(repoServices)

const FetchRepositoryProvider = ({ children }: any) => {
    return (
        <FetchRepositoryContext.Provider value={repoServices}>
            {children}
        </FetchRepositoryContext.Provider>
    )
}

const useFetchRepository = () => useContext(FetchRepositoryContext)

export { FetchRepositoryProvider, useFetchRepository }
export { repoServices }