import Markdown from "../Components/Markdown/Markdown";
import { useEffect, useState } from "react";
import { Publication } from "../../UseCases/Models/Publication";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useFetchRepository } from "../../UseCases/Tools/useRepository";

export default function PublicationDetails() {
    const [loading, setLoading] = useState(true)
    const [publication, setPublication] = useState<Publication>()
    const [notFound, setNotFound] = useState(false)
    const fetch = useFetchRepository()

    const { id } = useParams()

    const fetchPublication = async (id: string) => {
        const pub = await fetch.publications.getPublication(id)
        setPublication(pub)
    }

    useEffect(() => {
        setLoading(true)
        if (id) {
            fetchPublication(id).then(() => setLoading(false))
        }
        else {
            setNotFound(true)
            setLoading(false)
        }

    }, [])

    const getDate = () => {
        if (!publication) {
            return ''
        }
        return new Intl.DateTimeFormat('fr', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short'
        }).format(new Date(publication!.date))
    }


    return (
        <>
            {
                loading
                    ? <div className="circular-progress">
                        <CircularProgress />
                    </div>
                    : notFound
                        ? '404 Not Found'
                        : <>
                            <div className="publication-content">
                                <div className="date">
                                    {getDate()}
                                </div>
                            </div>
                            <Markdown input={publication?.body} />
                        </>
            }
        </>
    )
}