import { FetchCallback } from "../Repositories/Repository"

interface APIRequest {
    url: string
    cb: FetchCallback
}

export default class FetchService {
    private requestsQueue: APIRequest[] = []
    private isExec: boolean = false

    fetchApi(url: string, cb: FetchCallback) {
        this.requestsQueue.push({
            url: url,
            cb: cb
        })

        this.run()
    }

    private run() {
        if (this.requestsQueue.length <= 0) {
            this.isExec = false
            return
        }

        if (!this.isExec) {
            this.isExec = true

            this.exec()

            this.isExec = false
        }

    }

    private async exec() {
        do {
            const request = this.requestsQueue.shift()
            if (request === undefined) {
                continue
            }

            const response = await fetch(request.url)
            if (response.ok) {
                request.cb(undefined, response)
            }
            else {
                request.cb(new Error(`request failed with code: ${response.status}`, { cause: response.statusText }))
            }

            setTimeout(() => { }, 300)
        } while (this.requestsQueue.length > 0)
    }

}