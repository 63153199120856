import { visit } from "unist-util-visit";


const remarkAlignment = () => {
    type Alignment = 'left' | 'right' | 'center' | 'justify' | 'none'

    type AlignPattern = {
        alignment: Alignment,
        pattern: string
    }

    /*
     * Syntaxe markdown
     * :-left alignment:-
     * -:right align-:
     * -:center alignment:-
     * :-justify alignment-:
     */
    const alignPatterns: AlignPattern[] = [
        { alignment: 'left', pattern: '(:-)(.*)\n?\r?(.*)(:-)' },
        { alignment: 'right', pattern: '(-:)(.*)\n?\r?(.*)(-:)' },
        { alignment: 'center', pattern: '(-:)(.*)\n?\r?(.*)(:-)' },
        { alignment: 'justify', pattern: '(:-)(.*)\n?\r?(.*)(-:)' },

    ]

    function parseText(value: string) {

        let alignment: Alignment = 'none'
        for (let align of alignPatterns) {
            const rex = new RegExp(align.pattern, 'i')
            const match = value.match(rex)

            if (match) {
                alignment = align.alignment
                value = match[2]
            }
        }

        return {
            alignment: alignment,
            value: value
        }
    }

    function transformer(tree: any, file: any) {
        const src = file.value

        visit(tree, "paragraph", (node, index) => {
            const startOffset = node.position.start.offset
            const endOffset = node.position.end.offset

            let value = src.substring(startOffset, endOffset)

            if (!value)
                return

            const alignment = parseText(value)
            if (alignment.alignment === 'none') return


            for (let element of node.children) {
                if (element.type === 'text') {
                    element.value = element.value.replaceAll(/:-|-:/ig, '')
                }
            }

            node.data = {
                hProperties: {
                    align: alignment.alignment,
                }
            };
        });
    }

    return transformer;
};

export { remarkAlignment }