import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './Presentation/App';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { FetchRepositoryProvider } from './UseCases/Tools/useRepository';
import { KeyboardActionHandlerProvider } from './UseCases/Tools/KeyboardActionHandler';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>

      <KeyboardActionHandlerProvider>
        <FetchRepositoryProvider>
          <App />
        </FetchRepositoryProvider>
      </KeyboardActionHandlerProvider>

    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
