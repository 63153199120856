import { Box, Drawer, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import './NavBar.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNotifications } from '../Notifications/useNotifications';

export type MenuItem = {
    name: string
    target: string
    disabled?: boolean
}

type Props = {
    menu: MenuItem[]
}

type IconColor = "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"

export default function NavBar({ menu }: Props) {
    const [open, setOpen] = useState(false);
    const [opened, setOpened] = useState(false)
    const { toggle, hasNotification, notificationType } = useNotifications()

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const DrawerMenu = (
        <Box className="drawer-menu" role="presentation" onClick={toggleDrawer(false)}>
            <Box className='drawer-menu-content'>
                <div className="close-button">
                    <IconButton aria-label='close navigation menu' onClick={toggleDrawer(false)}>
                        <CloseIcon fontSize='large' />
                    </IconButton>
                </div>
                <div className="menu-list">
                    {menu.map((page, i) => (
                        (
                            !page.disabled &&
                            <Link to={page.target} key={i}>
                                {page.name}
                            </Link>
                        )
                    ))}
                </div>
            </Box>
        </Box>
    );

    const [animated, setAnimate] = useState(true)
    const openNotifications = () => {
        toggle()
        setOpened(true)
        setAnimate(false)
    }

    return (
        <Box className="header">
            <div className="app-menu">
                <div className="socials-links">
                    <div className='notif-icon'>
                        {
                            hasNotification
                                ? <IconButton className={animated ? 'animate' : ''}
                                    onClick={openNotifications}>
                                    <NotificationsActiveIcon color={
                                        opened
                                            ? 'inherit' :
                                            notificationType as IconColor} />
                                </IconButton>
                                : <></>
                        }
                    </div>
                    {/* <IconButton aria-label="facebook">
                                    <FacebookIcon />
                                </IconButton> */}
                </div>
                <div className="menu-items">
                    <IconButton aria-label="menu"
                        onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        PaperProps={{
                            sx: { width: '100%', height: '100%', justifyContent: 'stretch' }
                        }}
                        open={open} onClose={toggleDrawer(false)}
                        anchor='right'>
                        {DrawerMenu}
                    </Drawer>
                </div>
                <div className="menu-desktop">
                    <div className="menu-list">
                        {menu.map((page, i) => (
                            (
                                !page.disabled &&
                                <div className="link-tile" key={i}>
                                    <Link to={page.target}>
                                        {page.name}
                                    </Link>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <Box className="reason">
                <Typography>
                    Association de lutte contre le projet de carrière
                </Typography>
            </Box>
            <Box className="title">
                <Typography>
                    <Link to="/">Pas de carrière à Laà-Mondrans</Link>
                </Typography>
            </Box>
        </Box >
    )
}