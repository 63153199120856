import { visit } from "unist-util-visit"

const remarkHighlight = () => {
    const transformer = (tree: any, file: any) => {

        visit(tree, 'text', (node, index, parent) => {
            let value = node.value
            const match = value.match(/==(.*)==/i)

            if (match) {
                // split original string
                const textChildren = value.split(/==/i)
                    .filter((el: string) => el !== '')
                    .map((el: string) => {
                        let text: any = {
                            type: 'text',
                            value: el,
                        }

                        if (el === match[1]) {
                            text.data = {
                                hName: 'mark'
                            }
                        }

                        return text
                    })

                const nodeChildren = parent.children.slice(0, index)
                textChildren.forEach((child: any) => nodeChildren.push(child))
                nodeChildren.push(parent.children.slice(index ? index : 1, parent.children.lenght - 1))
                parent.children = nodeChildren
            }

        })
    }

    return transformer
}

const highlightRender = (node: any) => {
    return <mark>{node.children}</mark>
}

export { remarkHighlight, highlightRender as TextHighlighting }