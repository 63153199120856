import { repoServices } from "./useRepository";

export default class ConfigService {
    async fetchConfigFile(fileId: string) {

        const fileContent = await repoServices.files.getFile(fileId)
        const parseFile = this.parseINIString(fileContent)

        return parseFile
    }

    private parseINIString(data: string) {
        const regex = {
            section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
            param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
            comment: /^\s*;.*$/
        };
        let value: any = []
        const lines = data.split(/[\r\n]+/);
        let section: string | null = null;

        lines.forEach(function (line) {
            if (regex.comment.test(line)) {
                return;
            }
            else if (regex.param.test(line)) {
                const match = line.match(regex.param);

                if (match !== null) {
                    if (section) {
                        value[section][match[1]] = match[2];
                    }
                    else {
                        value[match[1]] = match[2];
                    }
                }
            }
            else if (regex.section.test(line)) {
                const match = line.match(regex.section);

                if (match !== null) {
                    value[match[1]] = [];
                    section = match[1];
                } else if (line.length <= 0 && section) {
                    section = null;
                };
            }
        });
        return value;
    }

}