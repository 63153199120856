import { IAPIContactsRepository } from "../../Repositories/DataSources/APIRepository"
import Message from "../Models/Message"

export interface IContactRepository {
    subscribe(email: string): Promise<boolean>
    contact(message: Message): Promise<boolean>
}

export class ContactRepository implements IContactRepository {
    private repository: IAPIContactsRepository

    constructor(repository: IAPIContactsRepository) {
        this.repository = repository
    }

    async subscribe(email: string): Promise<boolean> {
        const [person] = await this.repository.subscribe(email)
        const hasId = Object.hasOwn(person, 'id')
        return hasId
    }

    async contact(message: Message): Promise<boolean> {
        const error = await this.repository.contact(message)
        return !error.error
    }

}