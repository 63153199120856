import { FetchUrl, IAPIFilesRepository, IAPIPublicationsRepository } from "../../Repositories/DataSources/APIRepository"
import { PublicationModel } from "../../Repositories/Models/PublicationModel"
import { Publication } from "../Models/Publication"
import { compareDates } from "../Tools/DateUtils"
import { IFetchRepository } from "./Repository"

export interface FetchPublicationsOptions {
    count: number
}

export interface IPublicationsRepository {
    listPublications(): Promise<Publication[]>
    getPublication(id: string): Promise<Publication>
    getLastPublications(options: FetchPublicationsOptions): Promise<Publication[]>

    listPressArticles(): Promise<Publication[]>
    getLastPressArticles(options: FetchPublicationsOptions): Promise<Publication[]>
}

export interface IFilesRepository {
    getFile(fileId: string): Promise<string>
}

export class PublicationsRepository implements IPublicationsRepository {
    private repository: IAPIPublicationsRepository
    private fetchRepository: IFetchRepository

    constructor(repository: IAPIPublicationsRepository, fetchRepository: IFetchRepository) {
        this.repository = repository
        this.fetchRepository = fetchRepository
    }

    async getPublication(id: string): Promise<Publication> {
        return new Promise<Publication>((resolve, reject): void => {
            this.fetchRepository.fetch<PublicationModel>((({ createdTime, content, name }) => {
                try {
                    resolve({ id: id, date: createdTime, body: content, name: name } as Publication)

                } catch (error) {
                    reject(error)
                }

            }), (fetch: FetchUrl) => this.repository.getPublication(fetch, id))
        })

    }

    async listPublications(): Promise<Publication[]> {
        return new Promise<Publication[]>((resolve, reject): void => {
            this.fetchRepository.fetch<PublicationModel[]>((data => {
                try {
                    const result = data.map(pm => ({ id: pm.id, date: pm.createdTime, body: pm.content } as Publication))
                    resolve(result)

                } catch (error) {
                    reject(error)
                }

            }), this.repository.listPublications)
        })

    }

    async getLastPublications(options: FetchPublicationsOptions): Promise<Publication[]> {
        return (await this.listPublications())
            .sort((a, b) => compareDates(a.date, b.date)).reverse()
            .slice(0, options.count)

    }

    async listPressArticles(): Promise<Publication[]> {
        return new Promise<Publication[]>((resolve, reject): void => {
            this.fetchRepository.fetch<PublicationModel[]>((data => {
                try {
                    const result = data.map(pm => ({ id: pm.id, date: pm.createdTime, body: pm.content } as Publication))
                    resolve(result)

                } catch (error) {
                    reject(error)
                }

            }), this.repository.listPressArticles)
        })

    }

    async getLastPressArticles(options: FetchPublicationsOptions): Promise<Publication[]> {
        return (await this.listPressArticles())
            .sort((a, b) => compareDates(a.date, b.date)).reverse()
            .slice(0, options.count)
    }
}

export class FilesRepository implements IFilesRepository {
    private repository: IAPIFilesRepository
    private fetchRepository: IFetchRepository

    constructor(repository: IAPIFilesRepository, fetchRepository: IFetchRepository) {
        this.repository = repository
        this.fetchRepository = fetchRepository
    }

    async getFile(id: string): Promise<string> {
        return new Promise<string>((resolve, reject): void => {
            this.fetchRepository.fetch<PublicationModel>((({ content }) => {
                try {
                    resolve(content as string)

                } catch (error) {
                    reject(error)
                }

            }), (fetch: FetchUrl) => this.repository.getFile(fetch, id)
            )
        })

    }
}