export type StorageType = 'sessionStorage' | 'localStorage'

export default class StorageService {
    private storageType: StorageType
    private store

    constructor(storageType: StorageType) {
        this.storageType = storageType
        this.store = window[this.storageType]
    }

    isAvailable() {
        const storage = this.store, x = "__storage_test__";

        try {
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === "QuotaExceededError" ||
                    // Firefox
                    e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0
            );
        }
    }

    putData(key: string, value: any) {
        if(this.isAvailable()) {
            this.store[key] = value
        }
    }

    getData(key: string): any {
        return !this.isAvailable() ? null : this.store.getItem(key)
    }

}