import { useEffect, useState } from "react"

import { Publication } from "../../UseCases/Models/Publication";

import { compareDates } from "../../UseCases/Tools/DateUtils";

import { CircularProgress, Divider } from "@mui/material";
import Markdown from "../Components/Markdown/Markdown";
import { useFetchRepository } from "../../UseCases/Tools/useRepository";

export default function Press() {
    const [loading, setLoading] = useState(true);
    const [articlesList, setArticlesList] = useState<Publication[]>([]);
    const fetch = useFetchRepository()

    useEffect(() => {
        fetch.publications.listPressArticles()
            .then(data => {
                setArticlesList(data.sort((a, b) => compareDates(a.date, b.date)).reverse())
                setLoading(false)
            })
    })

    return (
        <>
            {
                loading
                    ? <div className="circular-progress">
                        <CircularProgress />
                    </div>
                    :
                    <div className="press-articles">
                        <h1>La presse en parle</h1>
                        {
                            articlesList.map((pub, i) => (
                                <div className="article" key={i}>
                                    <Markdown input={pub?.body} />
                                    <Divider />
                                </div>
                            ))
                        }
                    </div>
            }
        </>
    )
}