import { useEffect, useState } from 'react';
import { Publication } from '../../../UseCases/Models/Publication';
import Markdown from '../Markdown/Markdown';
import { Box, CircularProgress } from '@mui/material';
import { useFetchRepository } from '../../../UseCases/Tools/useRepository';

export default function MarkdownPage({ pageId }: { pageId: string }) {
    const [loading, setLoading] = useState(true);
    const [publication, setPublication] = useState<Publication>()

    const fetch = useFetchRepository()

    useEffect(() => {
        fetch.publications.getPublication(pageId)
            .then(pub => {
                setPublication(pub)
                setLoading(false)
            })
    }, [pageId])

    return (
        <Box className="publications">
            <Box className="publications-content">
                {
                    (loading)
                        ? <></>
                        : <Markdown input={publication?.body} />
                }
            </Box>
            <Box className="publications-loading">
                {loading ? <CircularProgress /> : <></>}
            </Box>
        </Box>
    )
}