import { visit } from "unist-util-visit"

const colorPattern = /\{(.*?)\{(.*?)\}\}/

const remarkColor = () => {
    const transformer = (tree: any) => {
        visit(tree, 'text', (node, index, parent) => {
            let text = node.value

            const match = text.match(new RegExp(colorPattern, 'i'))
            if (!match) return

            index = index ?? 0
            let newChildren = parent.children.slice(0, index)
            const values = text.split(new RegExp(/(\{.*?\{.*?\}\})/, 'ig'))

            const newNodes = []
            for (let value of values) {
                const match = value.match(new RegExp(colorPattern, 'i'))
                if (match) {
                    newNodes.push({
                        type: 'text',
                        value: match[2],
                        data: {
                            hName: 'span',
                            hProperties: { color: match[1] }
                        }
                    })
                }

                else if (value !== '') {
                    newNodes.push({
                        type: 'text',
                        value: value
                    })
                }
            }

            newChildren = newChildren.concat(newNodes)

            const children = newChildren.concat(parent.children.slice(index + 1))
            parent.children = children
        })

    }

    return transformer
}

const render = (node: any) => {
    const color = node.color
    return (
        <>
            {
                color ?
                    <span style={{ color: color }}>
                        {node.children}
                    </span>
                    : <span>{node.children}</span>
            }
        </>
    )
}

export { remarkColor, render as TextColorizing }