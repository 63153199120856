import { visit } from "unist-util-visit";

const remarkUnderline = () => {
    function transformer(tree: any, file: any) {
        const textSrc = file.value

        // Basically, find all strong nodes.
        // Convert strong nodes created by "__" to a different unist node type.
        visit(tree, "strong", (node, index, parent) => {
            const startOffset = node.position.start.offset
            const endOffset = node.position.end.offset

            const value = textSrc.substring(startOffset, endOffset)
            const wasUnderscores = value.startsWith("__") && value.endsWith("__");

            if (wasUnderscores) {
                node.type = "underline";
                node.data = {
                    hName: "u",
                    hProperties: {}
                }
            }
        })
    }

    return transformer;
};

const underlineRender = (node: any) => <u>{node.children}</u>

export { remarkUnderline, underlineRender as TextUnderline }