import { useEffect, useRef, useState } from "react"
import { Publication } from "../../../UseCases/Models/Publication"
import Markdown, { useResizeOnLoad } from "../Markdown/Markdown"
import { Link } from "react-router-dom"
import { Divider } from "@mui/material"
import { useFetchRepository } from "../../../UseCases/Tools/useRepository"
import { getFirstImage, getTitle, getSummary } from "../../../UseCases/Tools/PublicationUtils"

type Props = {
    pageId: string
}

type Image = {
    alt: string,
    url: string
}

export default function Sidebar(props: Props) {
    const [lastArticle, setLastArticle] = useState<Publication>()
    const [image, setImage] = useState<Image>({ alt: '', url: '' })
    const [title, setTitle] = useState('')
    const [summary, setSummary] = useState('')

    const elementRef = useRef<HTMLDivElement>(null)
    const maxWidth = useResizeOnLoad(elementRef, summary)

    const fetch = useFetchRepository()

    useEffect(() => {
        fetch.publications.getPublication(props.pageId)
            .then((pub: Publication) => {
                const body = pub.body

                const title = getTitle(body)
                if (title) {
                    setTitle(title)
                }

                const [alt, url] = getFirstImage(body) as string[]
                if (url) {
                    setImage({ alt: alt, url: url })
                }

                const summary = getSummary(body)
                if (summary) {
                    setSummary(summary)
                }

            })

        fetch.publications.getLastPressArticles({ count: 1 })
            .then(articles => setLastArticle(articles[0]))
    }, [props.pageId])

    return (
        <div className="content">
            {
                summary
                    ? <div className="about" ref={elementRef}>
                        <h1>{title}</h1>
                        <img src={image.url} alt={image.alt} style={{ maxWidth: maxWidth }} />
                        <Markdown input={summary} />
                        <Link to='/project' >Lire plus »</Link>
                    </div>
                    : <></>
            }

            <Divider />

            {
                lastArticle
                    ? <div className="press">
                        <h1>Presse</h1>
                        <Markdown input={lastArticle?.body} />
                        <Link to='/press'>Plus d’articles »</Link>
                    </div>
                    : <></>
            }
        </div>
    )
}