import { useEffect, useState } from "react"

import { Publication } from "../../UseCases/Models/Publication";

import { compareDates } from "../../UseCases/Tools/DateUtils";
import SummaryComponent from "../Components/Publications/SummaryComponent";

import { CircularProgress } from "@mui/material";
import { useFetchRepository } from "../../UseCases/Tools/useRepository";

export default function Publications() {
    const [loading, setLoading] = useState(true);
    const [publicationsList, setPublicationsList] = useState<Publication[]>([]);
    const fetch = useFetchRepository()

    useEffect(() => {
        fetch.publications.listPublications()
            .then(data => {
                setPublicationsList(data.sort((a, b) => compareDates(a.date, b.date)).reverse())
                setLoading(false)
            })
            .catch(console.log)
    })

    return (
        <>
            {
                loading
                    ? <div className="circular-progress">
                        <CircularProgress />
                    </div>
                    :
                    publicationsList.map((pub, i) => (
                        <SummaryComponent publication={pub} key={i} />
                    ))
            }
        </>
    )
}