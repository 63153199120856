export type DateNumber = Date | number | string;

export const compareDates = (d1: DateNumber, d2: DateNumber) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
        return -1
    }
    if (date1 > date2) {
        return 1
    }
    return 0
}